// let BASE_URL = process.env.NODE_ENV !== "development" ? "https://admin.jxnmw.cn/" : "http://192.168.1.232:13007/";
let BASE_URL = process.env.NODE_ENV !== "development" ? "https://yihuoerp.app65.cn/" : "http://8.140.207.158:9101/";
// let BASE_URL = 'https://admin.jxnmw.cn/'
module.exports = {
  // 系统名称
  title: "易货",
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  // 必填: api地址, 换成自己的域名即可
  // 例如: https://www.你的域名.com/index.php?s=/store
  BASE_API_production: BASE_URL + "store/",
  // BASE_URL: 'https://admin.jxnmw.cn/',//域名
  BASE_API: BASE_URL + "store/",
  BASE_URL: BASE_URL,
};
